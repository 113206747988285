import * as Sentry from '@sentry/nextjs';

/**
 * Generic function for error reporting.
 * @param error
 * @returns {null}
 */
const reportError = (error: any) => {
  if (process.env.NODE_ENV !== 'test') {
    console.error(error); //eslint-disable-line
  }
  if (!error?.response) {
    Sentry.captureException(error);
  }
  return null;
};

export default reportError;
