import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  AuthPayload,
  LoginState,
  ChangePasswordPayload,
  PreviewLoginState,
  CMS_USER_TYPE,
} from '@definitions/cmsUserAuth.types';
import { ResetPasswordConfirmPayload, ResetPasswordPayload } from '@definitions/common.types';
import { GlobalState } from '@modules/createStore';

import api from '../../services/api';

const PREFIX = 'CMS_USER_AUTH';

export const login = createAsyncThunk<AuthPayload, LoginState>(`${PREFIX}/LOGIN`, async (formData) => {
  const { data }: { data: { token: string } } = await api.post('/auth/token/', formData);

  setAuthorizationToken(data.token);
  return { ...data, role: CMS_USER_TYPE.GALLERY };
});

export const previewLogin = createAsyncThunk<AuthPayload, PreviewLoginState>(
  `${PREFIX}/PREVIEW_LOGIN`,
  async (formData, { rejectWithValue }) => {
    try {
      const { data }: { data: { token: string } } = await api.post('/auth/preview_token/', formData);

      setAuthorizationToken(data.token);
      return { ...data, role: CMS_USER_TYPE.PREVIEW };
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e.response);
      }

      return rejectWithValue({});
    }
  }
);

export const logout = createAction(`${PREFIX}/LOGOUT`, () => {
  setAuthorizationToken(null);
  return { payload: {} };
});

export const setAuthorizationToken = (token: string | null) => {
  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return;
  }

  api.defaults.headers.common.Authorization = null;
};

export const resetPassword = createAsyncThunk<void, ResetPasswordPayload>(
  `${PREFIX}/RESET_PASSWORD`,
  async (formData) => {
    const { data } = await api.post('/password-reset/', formData);

    return data;
  }
);

export const resetPasswordConfirm = createAsyncThunk<ResetPasswordConfirmPayload, ResetPasswordConfirmPayload>(
  `${PREFIX}/RESET_PASSWORD_CONFIRM`,
  async (formData) => {
    const { data } = await api.post('/password-reset/confirm/', formData);

    return data;
  }
);

export const changePassword = createAsyncThunk<AuthPayload, ChangePasswordPayload, { state: GlobalState }>(
  `${PREFIX}/CHANGE_PASSWORD`,
  async (formData, thunkAPI) => {
    const { token } = thunkAPI.getState().cmsUserAuth;
    setAuthorizationToken(token);
    const { data }: { data: { jwtToken: string } } = await api.post('/auth/change-password/', formData);
    setAuthorizationToken(data.jwtToken);
    return { token: data.jwtToken, role: CMS_USER_TYPE.GALLERY };
  }
);
