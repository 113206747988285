import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { Profile, CmsUserProfileState } from '@definitions/cmsUserProfile.types';

import * as actions from './cmsUserProfile.actions';

export const initialState: CmsUserProfileState = { profile: { galleryName: '' } };

const handleFetched = (state: CmsUserProfileState, { payload }: PayloadAction<Profile>) => {
  state.profile = payload;
};
const handleClearProfile = (state: CmsUserProfileState) => {
  state.profile = initialState.profile;
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.fetch.fulfilled, handleFetched);
  builder.addCase(actions.clearProfile, handleClearProfile);
});
