import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { path } from 'ramda';
import applyCaseMiddleware from 'axios-case-converter';
import { stringify } from 'query-string';

import { ROUTE } from '@utils/constants';

const baseURL = process.env.API_URL || '/backend';
const api = applyCaseMiddleware(
  axios.create({
    baseURL,
    paramsSerializer: (params: { params: object }) => stringify(params, { arrayFormat: 'none', skipEmptyString: true }),
  })
);

api.interceptors.response.use(
  (response: any) => response,
  (error: Error) => {
    const status = path<number>(['response', 'status'])(error);
    if (StatusCodes.UNAUTHORIZED === status) {
      window.location.href = ROUTE.LOGOUT;
    }
    return Promise.reject(error);
  }
);

export default api;
