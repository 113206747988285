import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, ThunkDispatch, unwrapResult } from '@reduxjs/toolkit';

import { cmsUserAuthActions, cmsUserAuthSelectors } from '@modules/cmsUserAuth';
import { ChangePasswordPayload, CMS_USER_TYPE, LoginState, PreviewLoginState } from '@definitions/cmsUserAuth.types';
import { ResetPasswordConfirmPayload, ResetPasswordPayload } from '@definitions/common.types';
import { GlobalState } from '@modules/createStore';
import { cmsUserProfileActions } from '@modules/cmsUserProfile';

type AppDispatch = ThunkDispatch<GlobalState, any, AnyAction>;

export const useLogin = () => {
  const dispatch: AppDispatch = useDispatch();

  const isAuthed = useSelector(cmsUserAuthSelectors.selectIsAuthed);
  const role = useSelector(cmsUserAuthSelectors.selectRole);

  const login = (data: LoginState) => dispatch(cmsUserAuthActions.login(data)).then(unwrapResult);

  return { isAuthed: isAuthed && role === CMS_USER_TYPE.GALLERY, login };
};

export const usePreviewLogin = () => {
  const dispatch: AppDispatch = useDispatch();

  const isAuthed = useSelector(cmsUserAuthSelectors.selectIsAuthed);
  const role = useSelector(cmsUserAuthSelectors.selectRole);

  const previewLogin = (data: PreviewLoginState) => dispatch(cmsUserAuthActions.previewLogin(data)).then(unwrapResult);

  return { isAuthed: isAuthed && role === CMS_USER_TYPE.PREVIEW, previewLogin };
};

export const useResetPassword = () => {
  const dispatch: AppDispatch = useDispatch();

  const resetPassword = (data: ResetPasswordPayload) =>
    dispatch(cmsUserAuthActions.resetPassword(data)).then(unwrapResult);

  return { resetPassword };
};

export const useChangePassword = () => {
  const dispatch: AppDispatch = useDispatch();

  const changePassword = (data: ChangePasswordPayload) =>
    dispatch(cmsUserAuthActions.changePassword(data)).then(unwrapResult);

  return { changePassword };
};

export const useResetPasswordConfirm = () => {
  const dispatch: AppDispatch = useDispatch();

  const resetPasswordConfirm = (data: ResetPasswordConfirmPayload) =>
    dispatch(cmsUserAuthActions.resetPasswordConfirm(data)).then(unwrapResult);

  return { resetPasswordConfirm };
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const isAuthed = useSelector(cmsUserAuthSelectors.selectIsAuthed);

  const logout = () => dispatch(cmsUserAuthActions.logout()) && dispatch(cmsUserProfileActions.clearProfile());

  return { logout, isAuthed };
};
