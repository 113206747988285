export enum COLORS {
  WILD_SAND = '#f5f5f5',
  MERCURY = '#e5e5e5',
  RED = '#ff5555',
  BLACK = '#000',
  BLACK10 = 'rgba(0, 0, 0, 0.1)',
  BLACK15 = 'rgba(0, 0, 0, 0.15)',
  BLACK20 = 'rgba(0, 0, 0, 0.2)',
  BLACK25 = 'rgba(0, 0, 0, 0.25)',
  BLACK30 = 'rgba(0, 0, 0, 0.3)',
  BLACK40 = 'rgba(0, 0, 0, 0.4)',
  BLACK50 = 'rgba(0, 0, 0, 0.5)',
  BLACK60 = 'rgba(0, 0, 0, 0.6)',
  WHITE = '#fff',
  WHITE25 = 'rgba(255, 255, 255, 0.25)',
  WHITE40 = 'rgba(255, 255, 255, 0.4)',
  WHITE60 = 'rgba(255, 255, 255, 0.6)',
  WHITE80 = 'rgba(255, 255, 255, 0.8)',
  GALLERY = '#eeeeee',
  YELLOW = '#ffff00',
  GOLDEN_FIZZ = '#f0fb33',
  PORTICA = '#f6e16a',
  DOVE_GRAY = '#666',
  MAROON_FLUSH = '#cf1f4e',
  GUARDSMAN_RED = '#cc0000',
  PALE_LEAF = '#C4D1BA',
  AZURE_RADIANCE50 = 'rgba(0, 162, 255, 0.5)',
  EMERALD40 = 'rgb(79, 193, 88, 0.4)',
  SILK = '#c5b4ad',
  CORN = '#e4c701',
  GLACIER = '#70b6c0',
  MARIGOLD = '#b57d2a',
  MARTINI = '#b8a5a7',
  JAFFA = '#ec8737',
  BERMUDA = '#82c3d9',
  SYCAMORE = '#89a046',
  BERYL_GREEN = '#e5e3bc',
  SUSHI = '#8fab3c',
  SILVER = '#ccc',
  SILVER_CHALICE = '#AAAAAA',
  CURIOUS_BLUE = '#2d8cd0',
  QUILL_GRAY = '#d6d6d1',
  JAPANESE_LAUREL = '#01800b',
  ALTO = '#ddd',
  EDWARD = '#B0B1B1',
  DARK_GREY = '#999',
}
