import { createSelector } from '@reduxjs/toolkit';

import { CmsUserAuthState } from '@definitions/cmsUserAuth.types';

import { GlobalState } from '../createStore';

export const selectCmsUserAuthDomain = (state: GlobalState) => state.cmsUserAuth;

export const selectIsAuthed = createSelector(selectCmsUserAuthDomain, (state: CmsUserAuthState) => state.isAuthed);
export const selectRole = createSelector(selectCmsUserAuthDomain, (state: CmsUserAuthState) => state.role);
