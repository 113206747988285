import { RehydrateAction } from 'redux-persist';

export interface CmsUserAuthState {
  isAuthed: boolean;
  role: CMS_USER_TYPE | null;
  token: string | null;
}

export interface LoginState {
  email: string;
  password: string;
}

export interface PreviewLoginState {
  sesame: string;
}

export interface AuthPayload {
  token: string | null;
  role: CMS_USER_TYPE;
}

export interface ChangePasswordPayload {
  newPassword: string;
  oldPassword: string;
}

export interface CmsUserAuthPayload {
  cmsUserAuth: CmsUserAuthState;
}

export interface RehydratePayload extends RehydrateAction {
  payload: CmsUserAuthPayload;
}

export enum CMS_USER_TYPE {
  GALLERY = 'gallery',
  PREVIEW = 'preview',
}
