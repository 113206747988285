import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { Profile } from '@definitions/cmsUserProfile.types';

import api from '../../services/api';
import { setAuthorizationToken } from '../cmsUserAuth/cmsUserAuth.actions';
import { GlobalState } from '../createStore';

const PREFIX = 'CMS_USER_PROFILE';

export const fetch = createAsyncThunk<Profile, void, { state: GlobalState }>(
  `${PREFIX}/FETCH`,
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().cmsUserAuth;
    setAuthorizationToken(token);
    const response = await api.get('/auth/me/');

    return response.data;
  }
);

export const clearProfile = createAction(`${PREFIX}/CLEAR_PROFILE`);
