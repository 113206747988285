import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { CmsUserAuthState, AuthPayload, RehydratePayload } from '@definitions/cmsUserAuth.types';
import api from '@services/api';

import * as actions from './cmsUserAuth.actions';

export const initialState: CmsUserAuthState = { isAuthed: false, token: null, role: null };

const handleAuth = (state: CmsUserAuthState, { payload }: PayloadAction<AuthPayload>) => {
  state.isAuthed = true;
  state.token = payload.token;
  state.role = payload.role;
};

const handleLogout = (state: CmsUserAuthState) => {
  state.isAuthed = false;
  state.token = null;
  state.role = null;
};

const handleRehydrate = (_: any, { payload }: RehydratePayload) => {
  if (payload?.cmsUserAuth?.token) {
    api.defaults.headers.common.Authorization = `Bearer ${payload.cmsUserAuth.token}`;
  }
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.login.fulfilled, handleAuth);
  builder.addCase(actions.previewLogin.fulfilled, handleAuth);
  builder.addCase(actions.changePassword.fulfilled, handleAuth);
  builder.addCase(actions.logout, handleLogout);
  builder.addCase('persist/REHYDRATE', handleRehydrate);
});
